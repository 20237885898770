import React, { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default class PanZoomImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: true,
      limitToBounds: false,
      minScale: 0.1,
      maxScale: 20,
      disabled: false,
      velocityDisabled: true,
      positionX: 0,
      positionY: 0,
      scale: 1
    };
    this.saveState.bind(this);
    this.saveStateMovement.bind(this);
    this.disableImage.bind(this);
  }

  disableImage = (e) => {
    this.setState(state => ({
      disabled: !state.disabled
    }));
    this.saveState(e);
  };

  saveState(e, x, y, s) {
    e.preventDefault();
    this.setState({
      positionX: x,
      positionY: y,
      scale: s
    })
  }

  saveStateMovement(e) {
    this.setState({
      positionX: e.positionX,
      positionY: e.positionY,
      scale: e.scale
    })
  }

  render() {
    const {
      limitToBounds,
      minScale,
      maxScale,
      disabled,
      velocityDisabled,
      positionX,
      positionY,
      scale
    } = this.state;
    let disableButtom;
    if (!this.state.disabled) {
      disableButtom = <button className="tool-option" onClick={(e) => this.disableImage(e)}>Lås</button>
    }
    else {
      disableButtom = <button className="tool-option" onClick={(e) => this.disableImage(e)}>Lås up</button>
    }
 
    return (
      <TransformWrapper
        initialScale={scale}
        initialPositionX={positionX}
        initialPositionY={positionY}
        disabled={disabled}
        minScale={minScale}
        maxScale={maxScale}
        limitToBounds={limitToBounds}
        wheel={{
          step: 0.1
        }}
        panning={{
          velocityDisabled: velocityDisabled
        }}
        onWheelStop={(e) => this.saveStateMovement(e)}
        onPanningStop={(e) => this.saveStateMovement(e)}
        onPinchingStop={(e) => this.saveStateMovement(e)}
        onZoomStop={(e) => this.saveStateMovement(e)}
        doubleClick={{
          step: 0.3
        }}
      >

      {(
        { zoomIn,
          zoomOut,
          resetTransform,
          ...rest 
        }) => (
          <React.Fragment>
            <div className="image-tools hidden-print">
              <button className="zoom-in-tools" onClick={() => zoomIn(0.1)}>Zoom in</button>
              <button className="zoom-out-tools" onClick={() => zoomOut(0.1)}>Zoom ut</button>
              {disableButtom}
              <button className="tool-option" onClick={disabled ? null : () => resetTransform()}>Reset</button>
              <button className="tool-option" onClick={() => this.props.removeImage(this.props.imageId)}>Ta bort</button>
            </div>  
            <TransformComponent
              wrapperClass='react-transform-component-full'
            >
            <img alt={`img - ${this.props.imageId}`} src={this.props.imageSrc} className="file-img" />
            </TransformComponent>
          </React.Fragment>
        )}
    </TransformWrapper>
      
    );
  }
}
