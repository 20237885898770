import React, { useState} from 'react';
import CalendarOptions from './CalendarOptions';
import Calendar from './Calendar';


function CalendarBuilder(props) {

  const [birthdays, setBirthdays] = useState({});
  const [names, setNames] = useState([]);
  const [showNames, setShowNames] = useState(true);
  const [showRedDays, setShowRedDays] = useState(true);
  const [showFlagDays, setShowFlagDays] = useState(true);
  const [showSpecialDays, setShowSpecialDays] = useState(true);
  const [numberOfMonths, setNumberOfMonths] = useState(12);
  const [showMonth, setShowMonth] = useState("All");
  const [showCalendarYear, setShowCalendarYear] = useState(true);
  const [calendarYear, setCalendarYear] =  useState(new Date().getFullYear()); 
  const [displaySpecial, setDisplaySpecial] =  useState({}); 
  const [headerPlacement, setHeaderPlacement] =  useState("Top");

  function saveConfig(e) {
    const config = {
      "birthdays": birthdays,
      "names": names,
      "showNames": showNames,
      "showRedDays": showRedDays,
      "showFlagDays": showFlagDays,
      "showThemeDays": showSpecialDays,
      "showCalendarYear": showCalendarYear,
      "headerPlacement": headerPlacement
    };
    exportToJson(config)
  }

  return (
    <div className="calendar-wrapper">
      <div className="calendar-inner-grid-container">

        <div className={`calendar-options hidden-print`}>
            <CalendarOptions 
              birthdays={birthdays}
              setBirthdays={setBirthdays}
              names={names}
              setNames={setNames}
              showNames={showNames}
              setShowNames={setShowNames}
              showRedDays={showRedDays}
              setShowRedDays={setShowRedDays}
              showFlagDays={showFlagDays}
              setShowFlagDays={setShowFlagDays}
              showSpecialDays={showSpecialDays}
              setShowSpecialDays={setShowSpecialDays}
              numberOfMonths={numberOfMonths}
              setNumberOfMonths={setNumberOfMonths}
              showMonth={showMonth}
              setShowMonth={setShowMonth}
              showCalendarYear={showCalendarYear}
              setShowCalendarYear={setShowCalendarYear}
              calendarYear={calendarYear}
              setCalendarYear={setCalendarYear}
              displaySpecial={displaySpecial}
              setDisplaySpecial={setDisplaySpecial}
              headerPlacement={headerPlacement}
              setHeaderPlacement={setHeaderPlacement}
              saveConfig={saveConfig}
            />
          </div>
        
        <div className="calendar-view">
          <div className="calendar">
            <Calendar         
              birthdays={birthdays}
              names={names}
              showNames={showNames}
              showRedDays={showRedDays}
              showFlagDays={showFlagDays}
              showSpecialDays={showSpecialDays}
              numberOfMonths={numberOfMonths}
              showMonth={showMonth}
              calendarYear={calendarYear}
              showCalendarYear={showCalendarYear}
              headerPlacement={headerPlacement}
            />
          </div>
        </div>

      </div>
    </div>
  )

}

function exportToJson(data) {
    let now = new Date();
    let filename = "familjens-kalender-export-" + now.toISOString() + ".json";
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      console.log("file option 1");
      var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(data, null, 2)))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      console.log("file option 2");
      var a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(data, null, 2));
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }


export default CalendarBuilder;