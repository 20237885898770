export let specialDaysInfo = {
  "se": {
    DEFAULT: {
      "red": false,
      "flag": false
    },
    "Nyårsdagen": {
      "red": true,
      "flag": true
    },
    "Trettondagsafton": {
      "red": false,
      "flag": false
    },
    "Trettondedag jul": {
      "red": true,
      "flag": false
    },
    "Konungens namnsdag": {
      "red": false,
      "flag": true
    },
    "Alla hjärtans dag": {
      "red": false,
      "flag": false
    },
    "Kronprinsessans namnsdag": { //Victoria
      "red": false,
      "flag": true
    },
    "Skärtorsdag": {
      "red": false,
      "flag": false
    },
    "Långfredagen": {
      "red": true,
      "flag": false
    },
    "Påskafton": {
      "red": false,
      "flag": false
    },
    "Påskdagen": {
      "red": true,
      "flag": true
    },
    "Annandag påsk": {
      "red": true,
      "flag": false
    },
    "Konungens födelsedag": {
      "red": false,
      "flag": true
    },
    "Valborgsmässoafton": {
      "red": false,
      "flag": false
    },
    "Första maj": {
      "red": true,
      "flag": true
    },
    "Kristi himmelsfärdsdag": {
      "red": true,
      "flag": false
    },
    "Pingstafton": {
      "red": false,
      "flag": false
    },
    "Pingstdagen": {
      "red": true,
      "flag": true
    },
    "Annandag pingst": {
      "red": false,
      "flag": false
    },
    "Veterandagen": {
      "red": false,
      "flag": true
    },
    "Mors dag": {
      "red": false,
      "flag": false
    },  
    "Nationaldagen": { //Sveriges nationaldag och svenska flaggans dag, Nationaldagen
      "red": true,
      "flag": true
    },
    "Flaggans dag": { //Sveriges nationaldag och svenska flaggans dag 
      "red": false,
      "flag": true
    },
    "Midsommarafton": {
      "red": false,
      "flag": false
    },
    "Midsommardagen": {
      "red": true,
      "flag": true
    },
    "Kronprinsessans födelsedag": {
      "red": false,
      "flag": true
    },
    "Drottningens namnsdag": {
      "red": false,
      "flag": true
    },
    "Riksdagsval": { //Dag för val till riksdagen
      "red": false,
      "flag": true
    },
    "FN-dagen": {
      "red": false,
      "flag": true
    },
    "Halloween": {
      "red": false,
      "flag": false
    },
    "Alla helgons dag": {
      "red": true,
      "flag": false
    },
    "Gustav Adolfsdagen": {
      "red": false,
      "flag": true
    },
    "Fars dag": {
      "red": false,
      "flag": false
    },
    "1:a advent": {
      "red": false,
      "flag": false
    },
    "2:a advent": {
      "red": false,
      "flag": false
    },
    "3:e advent": {
      "red": false,
      "flag": false
    },
    "4:e advent": {
      "red": false,
      "flag": false
    },
    "Nobeldagen": {
      "red": false,
      "flag": true
    },
    "Drottningens födelsedag": {
      "red": false,
      "flag": true
    },
    "Julafton": {
      "red": false, 
      "flag": false 
    },
    "Juldagen": {
      "red": true,
      "flag": true
    },
    "Annandag jul": {
      "red": true,
      "flag": false
    },
    "Nyårsafton": {
      "red": false,
      "flag": false
    }
  }
}

export let specialDaysInSwedenStatic = {
  "01-01": ["Nyårsdagen"],
  "01-05": ["Trettondagsafton"],
  "01-06": ["Trettondedag jul"],
  "01-28": ["Konungens namnsdag"],
  "02-14": ["Alla hjärtans dag"],
  "03-08": ["Internationella kvinnodagen"],
  "03-12": ["Kronprinsessans namnsdag"],
  "03-25": ["Våffeldagen"],
  "04-30": ["Konungens födelsedag", "Valborgsmässoafton"],
  "05-01": ["Första maj"],
  "05-17": ["Norges nationaldag"],
  "05-29": ["Veterandagen"],
  "06-06": ["Nationaldagen", "Flaggans dag"], //Svenska flaggans dag (förkortad)
  "07-14": ["Kronprinsessans födelsedag"],
  "08-08": ["Drottningens namnsdag"],
  "10-04": ["Kanelbullens dag"],
  "10-24": ["FN-dagen"],
  "10-31": ["Halloween"],
  "11-01": ["Allhelgonadagen"],
  "11-06": ["Gustav Adolfsdagen"],
  "11-19": ["Internationella mansdagen"],
  "12-10": ["Nobeldagen"],
  "12-23": ["Drottningens födelsedag"],
  "12-24": ["Julafton"],
  "12-25": ["Juldagen"],
  "12-26": ["Annandag jul"],
  "12-31": ["Nyårsafton"]
}
