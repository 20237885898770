import { getCalendarYearInfo, getAllSpecialDays } from './dateAPISweden'

export function getCountryCalendarYearInfo(year, countryCode) {
  if (countryCode === "se") {
    return getCalendarYearInfo(year)
  }
}

export function getSpecialDays(year, countryCode) {
  if (countryCode === "se") {
    return getAllSpecialDays(year)
  }
}