import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import CalendarBuilder from '../components/CalendarBuilder';
import '../css/calendar.css'

function CalendarPage() {
  return (
    <div className="calendar-big-wrapper">
      <Helmet htmlAttributes={{lang: 'sv'}}>
        <meta charSet="utf-8" />
        <title>Skapa en egen personlig kalender</title>
        <meta name="description" content="Skapa en egen personlig kalender med Familjens kalender! Välj ut dina bilder, födelsedagar, namnsdagar du vill ha med i din kalender. Skriv ut som PDF." />
      </Helmet>
      <div className="page-default-grid-layout">
        <header className="app-header hidden-print">
          <div className="app-header-aside-left">
          </div>
          <div className="app-header-center">
            <h1>Familjens kalender</h1> 
            <h2>Skapa en egen personlig kalender</h2>
            <h3>Välj ut bilder och vad du vill ha med i din personliga kalender</h3>
            <button className="app-header-button-click-me"><Link to="/instruktioner">Hitta instruktioner här</Link></button>
          </div>  
          <div className="app-header-aside-right">
          </div>
        </header>   
        <nav className="app-nav hidden-print">
          <Navigation />
        </nav>  

          <CalendarBuilder />

        <Footer/>
      </div>
    </div>
  )
}


export default CalendarPage;