import React from 'react';
import '../css/BooleanOption.css'

function BooleanOption({checked, handleChange}) {
  
  return (
    <label className="switch">
      <input type="checkbox" checked={checked} onChange={handleChange}/>
      <span className="slider round"></span>
    </label>
  )
}

export default BooleanOption