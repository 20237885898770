import React from 'react';
import sweflag from '../images/32px-Flag_of_Sweden.svg.png'

const DAYNUMBER = "day";

function CalendarDay(props) {

  let birthday;
  if (isBirthday(props)) {
    let birthdayColors = getBirthdayColors(props);
    let birthdays = getBirthdayNames(props);
    birthday = <div className="birthday"> {birthdays.map( (name, index) => <p key={index} className="birthday-name" style={{color: birthdayColors[index]}}>{name.toUpperCase()}</p>)}</div>
  }

  let redDay = "";
  if (isRedDay(getSpecialDayEvents(props)) && displayRedDay(props)) {
    redDay = "red-day";
  } 

  return (
    <div className={`calendar-day-wrapper calendar-day-grid ${getDayFromNumber(props.dayInfo[DAYNUMBER])} ${redDay}`}>
      <div className="day-number-item">
        <p>
          <span className="day-number">{getDayPartFromDate(props.date)}</span> 
        </p>
      </div>

      <div className="nameday-items"> 
        <NameDays
          nameDays={props.dayInfo.names}
          names={props.settings.names}
          showNames={props.settings.showNames}
        />
      </div>  

      <div className="birthday-items">
        {birthday}
      </div>

      <SpecialDay dayInfo={props.dayInfo} settings={props.settings}/> 

    </div>
  )
}

function NameDays(props) {
  let nameList = []
  let nameDays = props.nameDays

  if (props.names.length > 0) {
    for (let i = 0; i < nameDays.length; i++) {
      if (isHighlightName(nameDays[i], props.names)) {
        if (i === 0) {
          nameList.push(<React.Fragment key={i}><span className="highlight-name-day">{nameDays[i]}</span></React.Fragment>)
        } else if (nameList.length > 0) {
          nameList.push(<React.Fragment key={i}><span className="no-highlight-name-day">, </span><span className="highlight-name-day">{nameDays[i]}</span></React.Fragment>)
        }
        else {
          nameList.push(<React.Fragment key={i}><span className="highlight-name-day">{nameDays[i]}</span></React.Fragment>)
        }
      }
      else {
        if (props.showNames) {
          if (i === 0) {
            nameList.push(<React.Fragment key={i}><span className="no-highlight-name-day">{nameDays[i]}</span></React.Fragment>)
          } else if (nameList.length > 0) {
            nameList.push(<React.Fragment key={i}><span className="no-highlight-name-day">, {nameDays[i]}</span></React.Fragment>)
          }
        }
      }
      
    }

    return (
      <div className="calendar-name-day">
          <p>{nameList}</p>
      </div>

    )
  } else {
      if (nameDays === undefined) {
      }
      if(props.showNames) {
        return (<span className="no-highlight-name-day">{nameDays.join(', ')} </span>);
      }
      return "";
    
  }
}

function isHighlightName(name, highlightNames) {
  return highlightNames.includes(name.toLowerCase());
}

function SpecialDay(props) {

  if (isSpecialDay(props)) {
    let dayEvents = getSpecialDayEvents(props);
    dayEvents = dayEvents.sort((a, b) => 
    (a.red && a.flag && !b.red) ? -1 : (a.red && !a.flag && !b.red && b.flag) 
    ? -1: (!a.red && a.flag && !b.red && !b.flag) ? -1: 1);

    let isFlag = isFlagDay(props);
    let flagDay;
    if (isFlag && displayFlagDay(props)) {
      flagDay = <div className="flag-day"> <img alt="Svenska flaggan" className="flag-image" src={sweflag}/> </div> 
    }

    return (
      <React.Fragment>
      <div className={`special-day-item ${isFlag? "special-day-item-with-flag": ""}`}>
        {dayEvents.map(dayEvent => <DayEvent key={dayEvent.name} name={dayEvent.name} red={dayEvent.red} flag={dayEvent.flag} settings={props.settings} />)}
      </div>
      <div className="flag-item">
        {flagDay}
      </div>

      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>

      </React.Fragment>
    );
  }

}

function isSpecialDay(props) {
  return props.dayInfo.hasOwnProperty("special") && props.dayInfo.special.length > 0;
}

function getSpecialDayEvents(props) {
  if (isSpecialDay(props)) {
    return props.dayInfo.special;
  } else {
    return [];
  }
}

function DayEvent({name, red, flag, settings}) {
  if (red && settings.showRedDays) {
    return (
      <div className="red-day-text"><p>{name}</p></div>
    )
  } else if (flag && settings.showFlagDays) {
    return (
      <div className="flag-day-text"><p>{name}</p></div>
    )
  } else if (!red && !flag && settings.showSpecialDays) {
    return (
      <div className="special-day-text"><p>{name}</p></div>
    )
  } else {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }

}

function displayFlagDay(props) {
  return props.settings.showFlagDays;
}

function displayRedDay(props) {
  return props.settings.showRedDays;
}

function isFlagDay(props) {
  let dayEvents = getSpecialDayEvents(props);
  for (let event of dayEvents) {
    if (event.hasOwnProperty("flag") && event.flag === true) {
      return true;
    }
  }
  return false
}

function isRedDay(dayEvents) {
  for (let event of dayEvents) {
    if (event.hasOwnProperty("red") && event.red) {
      return true;
    }
  }
  return false
}

function getDayPartFromDate(date) {
  let day = date.slice(-2);
  if (day.charAt(0) === '0') {
    return day.charAt(1);
  } else {
    return day
  }
}


function getDayFromNumber(dayOfWeek) {
  switch(dayOfWeek) {
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
    case 0:
      return "sunday";
    default:
      return "unknown";
  }
}

function isBirthday(props) {
  let birthdayNames = getBirthdayNames(props)
  return birthdayNames.length > 0;

}

function getBirthdayNames(props) {
  let dayOfYear = getDayAndMonth(props.date);
  let birthdays = props.settings.birthdays;
  let birthdayNames = [];
  if (birthdays.hasOwnProperty(dayOfYear) && birthdays[dayOfYear]) {
    birthdays[dayOfYear].map( (value) => birthdayNames.push(value["name"] + getBirthdayYear(props, value))
    );
  }
  return birthdayNames;
}

function getBirthdayYear(props, birthdayInfo) {
  let text = "";
  if (birthdayInfo.hasOwnProperty("year") && birthdayInfo["year"] !== undefined && birthdayInfo["year"] !== "") {
    let age = props.settings.calendarYear - birthdayInfo["year"];
    if (age >= 0) {
      return " " + age + " år"
    }
    return text;
  }
  return text;
}

function getBirthdayColors(props) {
  let dayOfYear = getDayAndMonth(props.date);
  let birthdays = props.settings.birthdays;
  let birthdayColor = [];
  if (birthdays.hasOwnProperty(dayOfYear) && birthdays[dayOfYear]) {
    birthdays[dayOfYear].map( (value) => birthdayColor.push(value["color"])
    );
  }
  return birthdayColor;
}

function getDayAndMonth(date) {
  return date.slice(-5)
}

export default CalendarDay;

