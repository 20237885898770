import React from 'react';
import BooleanOption from './BooleanOption';
import Files from 'react-files';
import '../css/calendar.css'
import { getDaysInMonth } from "../dateInformationHandler/dateHelper";
import { useState } from 'react';

function CalendarOptions( 
  { birthdays, setBirthdays,
    names, setNames,
    showNames, setShowNames,
    showRedDays, setShowRedDays,
    showFlagDays, setShowFlagDays,
    showSpecialDays, setShowSpecialDays,
    showMonth, setShowMonth,
    showCalendarYear, setShowCalendarYear,
    calendarYear, setCalendarYear,
    headerPlacement, setHeaderPlacement,
    saveConfig, 
  }) {

  const [birthdayMonth, setBirthdayMonth] = useState("01");
  const [birthday, setBirthday] = useState(1);
  const [birthdayColor, setBirthdayColor] = useState("#0000ff");

  function onConfigFileChange(e) {
    const reader = new FileReader()
    reader.onload = async (e) => {
      try {
        const text = (e.target.result)
        let config = JSON.parse(text);
        if (!Array.isArray(config["birthdays"]) && config["birthdays"] instanceof Object) {
          setBirthdays(config["birthdays"]);
        } else {
          alert("Kunde inte läsa in födelsedagar")
        }
        
        if (Array.isArray(config["names"])) {
          setNames(config["names"]);
        } else {
          alert("Kunde inte läsa in namnsdagar")
        }

        if (typeof config["showNames"] == "boolean") {
          setShowNames(config["showNames"]);
        } else {
          alert("Kunde inte läsa in om alla namnsdagar ska visas")
        }

        if (typeof config["showRedDays"] == "boolean") {
          setShowRedDays(config["showRedDays"]);
        } else {
          alert("Kunde inte läsa in om röda dagar ska visas")
        }

        if (typeof config["showFlagDays"] == "boolean") {
          setShowFlagDays(config["showFlagDays"]);
        } else {
          alert("Kunde inte läsa in om flaggdagar ska visas")
        }

        if (typeof config["showThemeDays"] == "boolean") {
          setShowSpecialDays(config["showThemeDays"]);
        } else {
          alert("Kunde inte läsa in om temadagar ska visas")
        }

        if (typeof config["showCalendarYear"] == "boolean") {
          setShowCalendarYear(config["showCalendarYear"]);
        } else {
          alert("Kunde inte läsa in om år ska visas")
        }

        if (typeof config["headerPlacement"] == "string") {
          setHeaderPlacement(config["headerPlacement"]);
        } else {
          alert("Kunde inte läsa in vart rubriken ska placeras")
        }

      } catch(err) {
        alert("Filen kunde tyvärr inte läsas. Filen kanske har modifierats och fått fel format.")
      }
      
    };
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')

    let numberOfFiles = e.length - 1;
    if (numberOfFiles >= 0) {
      reader.readAsText(e[numberOfFiles]);
    }
  }

  function onConfigFileError() {
    alert("Tyvärr kunde inte filen läsas in korrekt!");
  }
  
  function addBirthday(event) {
    event.preventDefault();

    if(event.hasOwnProperty("currentTarget") && event.currentTarget.hasOwnProperty("birthday name") && event.currentTarget["birthday name"]
     && event.currentTarget.hasOwnProperty("birthday month") && event.currentTarget.hasOwnProperty("birthday day") && event.currentTarget.hasOwnProperty("birthday color")) {
      let day = 0;
      let year = undefined;
      if (event.currentTarget["birthday day"].value < 10) {
        day = "0" + event.currentTarget["birthday day"].value.toString();
      } else {
        day = event.currentTarget["birthday day"].value.toString();
      }
      let date = event.currentTarget["birthday month"].value.toString() + "-" + day ;

      
      if (event.currentTarget.hasOwnProperty("birthday year") && event.currentTarget["birthday year"].value !== undefined  && event.currentTarget["birthday year"].value !== "") {
        year = event.currentTarget["birthday year"].value;
      }

      let birthday = {};
      let birthdayInfo = {
        "date": date,
        "year": year,
        "name": event.currentTarget["birthday name"].value,
        "color": event.currentTarget["birthday color"].value};
      if (!birthdays.hasOwnProperty(date)) {
        birthday[date] = [birthdayInfo];
      } else {
        birthday[date] = [...birthdays[date], birthdayInfo];
      }

 
      
      setBirthdays(old => { return {...old, ...birthday}});
      setBirthday(event.currentTarget["birthday day"].value.toString())
      setBirthdayMonth(event.currentTarget["birthday month"].value.toString())
      setBirthdayColor(event.currentTarget["birthday color"].value);
      event.currentTarget.reset();
    }
  }

  function addNameDay(event) {
    event.preventDefault();
    let name = event.currentTarget["name day"].value;
    if(event.currentTarget !== null && event.hasOwnProperty("currentTarget") && event.currentTarget.hasOwnProperty("name day") && event.currentTarget["name day"]) {
      if(isValidName(name.toLowerCase())) {
        setNames(old => [...old, name.toLowerCase()]);
      }
      event.currentTarget.reset();
    }
  }

  function isValidName(name) {
    if (name.length === 0) { //TODO check for spaces or from list of dict of name + possible autocomplete
      return false;
    } else if (names.includes(name)) {
      return false;
    } else {
      return true;
    }
  }

  function onNamesCheckChange(event) {
    setShowNames(!showNames);
  }

  function onRedDaysCheckChange(event) {
    setShowRedDays(!showRedDays);
  }
  
  function onFlagDaysCheckChange(event) {
    setShowFlagDays(!showFlagDays);
  }

  function onSpecialDaysCheckChange(event) {
    setShowSpecialDays(!showSpecialDays);
  }
  
  function onShowMonthChange(event) {
    setShowMonth(event.target.value);
  }

  function onShowYearChange(event) {
    setShowCalendarYear(!showCalendarYear);
  }
  
  function onYearChange(event) {
    if (event.target.value < 2018) {
      setCalendarYear(2018);
    } else if (event.target.value > 2030) {
      setCalendarYear(2030);
    } 
    else {
      setCalendarYear(event.target.value);
    }
  }
  
  function removeNameFromList(index) {
    const newNames = [...names];
    newNames.splice(index, 1);
    setNames(newNames);
  }

  function removeBirthdayFromList(key, innerIndex) {
    const newBirthdays = {...birthdays};
    newBirthdays[key].splice(innerIndex, 1);

    if (newBirthdays[key].length === 0) {
      delete newBirthdays[key];
    }
    setBirthdays(newBirthdays);
  }

  function onBirthdayMonthChange(e) {
    setBirthdayMonth(e.target.value);
    let daysInMonth = getDaysInMonth(calendarYear, e.target.value)
    if (birthday > daysInMonth) {
      setBirthday(daysInMonth);
    }
  }

  function onBirthdayChange(e) {
    let daysInMonth = getDaysInMonth(calendarYear, birthdayMonth)
    if (e.target.value > daysInMonth) {
      setBirthday(daysInMonth);
    } else {
      setBirthday(e.target.value);
    }
  }

  function printCalendar(e) {
    try {
      //document.execCommand("print", false, null)
      // Safari 3.0+ "[object HTMLElementConstructor]" 
      let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

      if (isSafari) {
        // Print for Safari browser
        document.execCommand('print', false, null)
        //setTimeout(document.execCommand('print', false, null), 10)
        //safariPrint();      
      } else {
        window.print();
      }

    } catch(err) {
      alert(err)
      console.log(err);
    }
  }

  function onHeaderPlacementChange(event) {
    setHeaderPlacement(event.target.value);
  }

  return (
    <div className="options-grid-container">
      <div className="setting-container tooltip">
        <span className="tooltiptext">Välj en sparad fil med inställningar</span>
        <div className="save-button" >
          <Files
            key={new Date().getTime()}
            className='option-button-text option-button'
            onChange={onConfigFileChange}
            onError={onConfigFileError}
            accepts={['application/json']}
            maxFileSize={1000000}
            minFileSize={0}
            maxFiles={100}
            clickable
            test
          >
           <p>Ladda upp inställningar</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M16 16h-3v5h-2v-5h-3l4-4 4 4zm3.479-5.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"/></svg>
          </Files> 
          
        </div>
      </div>
      
      <div className="setting-container tooltip">
        <span className="tooltiptext">Sparar ner en fil till din dator</span>
        <div className="save-button option-button-text option-button " onClick={saveConfig}>
          <p>Spara ner inställningar</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M8 20h3v-5h2v5h3l-4 4-4-4zm11.479-12.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"/></svg>
        </div>
      </div>

      <div className="setting-container">
        <h2 className="option-header">Välj kalenderår</h2>
        <label htmlFor="yearOption">År du vill skapa en kalender för:</label><br/>
        <select id="yearOption" name="year" value={calendarYear} onChange={onYearChange}>
          <option value="2018">2018</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          <option value="2026">2026</option>
          <option value="2027">2027</option>
        </select>
      </div>

      <div className="show-month-setting setting-container">
        <h2 className="option-header">Välj kalendermånad</h2>
        <label htmlFor="monthOption">Månad du vill skapa en kalender för:</label><br/>
        <select id="monthOption" name="monthOption" value={showMonth} onChange={onShowMonthChange}>
          <option value="All">Alla</option>
          <option value="Januari">Januari</option>
          <option value="Febuary">Februari</option>
          <option value="March">Mars</option>
          <option value="April">April</option>
          <option value="May">Maj</option>
          <option value="June">Juni</option>
          <option value="July">Juli</option>
          <option value="August">Augusti</option>
          <option value="September">September</option>
          <option value="October">Oktober</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>
      </div>

      <div className="birthday-setting setting-container">
        <h2 className="option-header">Födelsedagar i kalendern</h2>
        <form  onSubmit={addBirthday}>

          <label htmlFor="birthday name">Ange namn:</label><br/>
          <input type="text" id="birthday name" name="birthday name" placeholder="Lägg till födelsedag för" required/><br/>

          <label htmlFor="birthday year">Välj födelseår (valfritt för att visa ålder):</label><br/>
          <input type="number" id="birthday year" min="0" name="birthday year"/>
          <br/>

          <label htmlFor="birthday month">Välj månad:</label><br/>
          <select id="birthday month" name="birthday month" value={birthdayMonth} onChange={onBirthdayMonthChange} required>
            <option value="01">Januari</option>
            <option value="02">Februari</option>
            <option value="03">Mars</option>
            <option value="04">April</option>
            <option value="05">Maj</option>
            <option value="06">Juni</option>
            <option value="07">Juli</option>
            <option value="08">Augusti</option>
            <option value="09">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>

          <label htmlFor="birthday day">Välj dag:</label><br/>
          <input type="number" id="birthday day" min="1" max={getDaysInMonth(calendarYear, birthdayMonth)}
          onChange={onBirthdayChange} name="birthday day" value={birthday} required/><br/>

          <label htmlFor="birthday color">Välj textfärg:</label>
          <input className="color-bar" type="color" id="birthday color" name="birthday color" defaultValue={birthdayColor} required/>
          <input className="clickme" type="submit"  value="Lägg till födelsedag"/>
          
        </form>

        <ul className="birthday-list">
          {Object.entries(birthdays).map( ([key, value], index)=>  value.map((innerValue, innerIndex) => 
           <li key={index + "-" + innerIndex}><p>{innerValue["name"]} {innerValue["year"] ? innerValue["year"] + "-" : null}{innerValue["date"]}  </p>
           <div style={{display: "inline-block", backgroundColor: innerValue["color"], width:"30px", marginRight: "5px", borderRadius:"100%"}}></div>
           <button className="remove-item" onClick={() => removeBirthdayFromList(key, innerIndex)}>X</button></li>
           ))
          }
        </ul>
      </div>

      <div className="names-setting setting-container ">
        <h2 className="option-header">Namnsdagar i kalendern</h2>
        <form onSubmit={addNameDay}>
          <label htmlFor="name day">Namnsdagar du vill ska sticka ut i din kalender:</label><br/>
          <input type="text" id="name day" name="name day" placeholder="Namnsdag" /><br/>
          <input className="clickme" type="submit" value="Lägg till namn"/>
        </form>
        <ul className="names-list">
          {names.map( (name, index )=> <li key={index}><p>{name}</p><button className="remove-item" onClick={() => removeNameFromList(index)}>X</button></li>)}
        </ul>
      
      </div>  
      
      <div className="boolean-setting setting-container">
        <div >
          <p className="option-header">Vill du visa alla namnsdagar i din kalender? </p>
          <BooleanOption checked={showNames} handleChange={onNamesCheckChange}/><br/>
        </div>

       
        <div>
        <p className="option-header">Vill du visa året i din kalender?</p>
          <BooleanOption checked={showCalendarYear} handleChange={onShowYearChange}/><br/>
        </div>

        <div>
          <p className="option-header">Vill du visa röda dagar i din kalender? </p> 
          <BooleanOption checked={showRedDays} handleChange={onRedDaysCheckChange}/><br/>
        </div>

      </div>

      <div className="boolean-setting setting-container ">
        <div>
        <p className="option-header">Vill du visa flaggdagar i din kalender?</p>
          <BooleanOption checked={showFlagDays} handleChange={onFlagDaysCheckChange}/><br/>
        </div>

        <div>
        <p className="option-header">Vill du visa övriga temadagar i din kalender?</p>
          <BooleanOption checked={showSpecialDays} handleChange={onSpecialDaysCheckChange}/><br/>
        </div>

      </div>

      <div className="setting-container" >
      <h2 className="option-header">Placering av kalenders rubrik</h2>
        <label htmlFor="header-placement">Välj placering:</label><br/>
        <select id="header-placement" name="header-placement" value={headerPlacement} onChange={onHeaderPlacementChange} required>
          <option value="Top">Top</option>
          <option value="Middle">Mellan</option>
          <option value="Bottom">Botten</option>
        </select>
      </div>
      <div className="setting-container" >
      </div>

      <div className="setting-container tooltip">
        <span className="tooltiptext">Öppnar <i>skriv ut</i> i webbläsaren</span>
        <div className="save-button" onClick={() => printCalendar()}> 
          <div className="option-button-text option-button">
            <p>Skriv ut din kalender</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"/></svg>
          </div>
        </div>
      </div>
      
    </div>
  )
}


export default CalendarOptions