export function getDayAndMonthFromString(date) {
  let parts = date.split('-');
  return parts[1] + "-" + parts[2];  
}

export function getYearFromString(date) {
  let parts = date.split('-');
  return parts[1]; 
}

export function getDayFromDateString(date) {
  let day = date.slice(-2);
  if (day.charAt(0) === '0') {
    return day.charAt(1);
  } else {
    return day
  }
}

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function getDateStringISO(date) { //Can change day
  return date.toISOString().split('T')[0];
}

function pad(number) {
  return (number < 10 ? '0' : '') + number;
}

export function getDateString(date) {

  return date.getFullYear() +
  '-' + pad(date.getMonth() + 1) +
  '-' + pad(date.getDate());
}

