import React, { useMemo } from 'react';
import CalendarMonthBuilder from "./CalendarMonthBuilder";
import { getCountryCalendarYearInfo } from "../dateInformationHandler/dateAPI";

function Calendar({ birthdays, names, showNames, showRedDays, showFlagDays, showSpecialDays, numberOfMonths, showMonth, calendarYear, showCalendarYear, headerPlacement}) {
  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  const countryCode = "se";
  const yearInfo = useMemo(() => getCountryCalendarYearInfo(calendarYear, countryCode), [calendarYear, countryCode])
  
  let settings = {
    "birthdays": birthdays,
    "calendarYear": calendarYear,
    "names": names,
    "showNames": showNames,
    "showRedDays": showRedDays,
    "showFlagDays": showFlagDays,
    "showSpecialDays": showSpecialDays,
    "showCalendarYear": showCalendarYear,
    "headerPlacement": headerPlacement
  }

  return (
    <div className=""> 
      {getMonths(months, showMonth).map(month => <CalendarMonthBuilder year={calendarYear} key={month} month={getMonthName(month, countryCode)} monthNumber={month} monthInfo={yearInfo[getMonthValue(month)]} settings={settings} />)}
    </div>
  )
}

function getMonthName(monthNumber, countryCode) { 
  const monthMapping = {
    "01": "Januari",
    "02": "Februari",
    "03": "Mars",
    "04": "April",
    "05": "Maj",
    "06": "Juni",
    "07": "Juli",
    "08": "Augusti",
    "09": "September",
    "10": "Oktober",
    "11": "November",
    "12": "December"
  }
  return monthMapping[monthNumber]
}

function getMonthNumber(month) { 
  const monthMapping = {
    "Januari": "01",
    "Febuary": "02",
    "March": "03",
    "April": "04",
    "May": "05",
    "June": "06",
    "July": "07",
    "August": "08",
    "September": "09",
    "October": "10",
    "November": "11",
    "December": "12"
  }
  return monthMapping[month];
}

function getMonthValue(monthNumber) {
  const monthMapping = {
    "01": 0,
    "02": 1,
    "03": 2,
    "04": 3,
    "05": 4,
    "06": 5,
    "07": 6,
    "08": 7,
    "09": 8,
    "10": 9,
    "11": 10,
    "12": 11
  }

  return monthMapping[monthNumber]
}

function getMonths(months, showMonth) {
  if (showMonth === "All" ) {
    return months;
  }
  else {
    return [getMonthNumber(showMonth)]
  }
}

export default Calendar;