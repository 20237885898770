import { getDayAndMonthFromString, getDateString} from './dateHelper'
import seNames2018 from '../data/namnsdagar2018.json';
import seNames2022 from '../data/namnsdagar2022.json';
import { specialDaysInfo, specialDaysInSwedenStatic } from './dateConstantsSweden';

let specialDaysInSwedenDynamic = {};
let specialDays = {};
const DEFAULT = "default";
const COUNTRY = "se"
const MONTHS = 12;

export function getCalendarYearInfo(year) {
  if (specialDays[year] === undefined) {
    specialDays[year] = getAllSpecialDays(year);
  }
  let yearInfo = {};
  for (let month = 0; month < MONTHS; month++) {
    yearInfo[month] = getCalendarMonthInfo(year, month);
  }
  return yearInfo;
}


export function getAllSpecialDays(year) {
  specialDaysInSwedenDynamic = getDynamicSpecialDay(year)
  let allSpecialDays = {...specialDaysInSwedenStatic};
  const dynamicKeys = Object.keys(specialDaysInSwedenDynamic);
  for (const key of dynamicKeys) {
    addSpecialDay(allSpecialDays, key, specialDaysInSwedenDynamic[key]);
  }

  return allSpecialDays
}

function addSpecialDay(obj, key, value) {
  if (Array.isArray(value)) {
    if (key in obj) {
      obj[key] = [...value, ...obj[key]]
    } else {
      obj[key] = value
    }
  } else {
      if (key in obj) {
        obj[key] = [value, ...obj[key]]
      } else {
        obj[key] = [value]
      }
  }
}

export function getCalendarMonthInfo(year, month) { 
  if (specialDays[year] === undefined) {
    specialDays[year] = getAllSpecialDays(year);
  }
  let monthInfo = {};
  let startDate = new Date(year, month, 1, 12);
  var endDate = new Date(year, month + 1, 1, 12);
  while (startDate < endDate) {
    let dateKey = getDateString(startDate);
    monthInfo[dateKey] = getInformationDate(startDate, COUNTRY);
    startDate.setDate(startDate.getDate() + 1) ;
  }
  return monthInfo;
}

function getInformationDate(date) {
  return {
    "day": date.getDay(), //0 Sunday
    "names": getNamesForDate(date),
    "week": getWeekNumber(date),
    "special": getDayInformation(date)
  } 
}

function getNamesForDate(date) {
  if (COUNTRY === 'se') {
    let year = date.getFullYear();
    if (year > 2021) {
      let names = seNames2022[getDayAndMonthFromString(getDateString(date))];
      if (names === undefined) {
        return [];
      }
      return names;
    } else if (2017 < year && year < 2022) {
      let names = seNames2018[getDayAndMonthFromString(getDateString(date))];
      if (names === undefined) {
        return [];
      }
      return names;
    } else {
      return [];
    }
  }
  else {
    return [];
  }
}

function getWeekNumber(orginalDate) {
  var date = new Date(orginalDate.getTime())
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week 1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}

function getDayInformation(date) {
  let info = [];
  let year = date.getFullYear().toString();
  let dateString = getDateString(date);
  let dayOfYear = getDayAndMonthFromString(dateString);

  if(specialDays[year] === undefined) {
    return info
  }
  if (dayOfYear in specialDays[year]) {
    let specialDay = specialDays[year][dayOfYear];
    for (const event of specialDay) {
      if (event in specialDaysInfo[COUNTRY]) {
        info.push({
          "name": event,
          ...specialDaysInfo[COUNTRY][event]
        })
      } else {
         info.push({
          "name": event,
          ...specialDaysInfo[COUNTRY][DEFAULT]
        })
      }
    }
  }
  return info;
}

function getDynamicSpecialDay(year) {
  let dynamicSpecialDays = {};

  let firstAdvent = getNthAdventDate(year, 1);
  let secondAdvent = getNthAdventDate(year, 2);
  let thirdAdvent = getNthAdventDate(year, 3);
  let fourthAdvent = getNthAdventDate(year, 4);
  let fathersDay = getDateString(nthDayInMonth(2, 0, 10, year)); //Second Sunday in November
  let mothersDay = getDateString(lastDayInMonth(0, 4, year)); //Last Sunday in May
  let easterDay = getEaster(year); //Sunday
  let easterMonday = getDateString(addDays(easterDay, 1));
  let holySaturday = getDateString(addDays(easterDay, -1));
  let goodFriday = getDateString(addDays(easterDay, -2));
  let maundyThursday = getDateString(addDays(easterDay, -3));
  let fettisdagen = getDateString(getFettisdagen(year));
  let holyThursday = getHolyThursday(easterDay);
  let pentecost = getPentecost(easterDay); // Pingsdagen
  let pentecostEve = getDateString(addDays(pentecost, -1)); // Pingstafton
  let pentecostPost = getDateString(addDays(pentecost, 1)); // Annandag pingst
  let midsummerDay = getMidsummerDay(year);
  let midsummerEvening = getDateString(addDays(midsummerDay, -1)); 
  let summerSolstice = getSummerSolstice(year);
  let winterSolstice = getWinterSolstice(year);
  let marchEquinox = getMarchEquinox(year);
  let septemberEquinox = getSeptemberEquinox(year);
  let electionDay = getElectionDay(year);
  let allSaintsDay = getAllSaintsDay(year);
  let allSaintsEve = getDateString(addDays(allSaintsDay, -1));

  addDynamicSpecialDay(dynamicSpecialDays, firstAdvent, "1:a advent");
  addDynamicSpecialDay(dynamicSpecialDays, secondAdvent, "2:a advent");
  addDynamicSpecialDay(dynamicSpecialDays, thirdAdvent, "3:e advent");
  addDynamicSpecialDay(dynamicSpecialDays, fourthAdvent, "4:e advent");
  addDynamicSpecialDay(dynamicSpecialDays, fathersDay, "Fars dag");
  addDynamicSpecialDay(dynamicSpecialDays, mothersDay, "Mors dag");
  addDynamicSpecialDay(dynamicSpecialDays, maundyThursday, "Skärtorsdagen");
  addDynamicSpecialDay(dynamicSpecialDays, goodFriday, "Långfredagen");
  addDynamicSpecialDay(dynamicSpecialDays, holySaturday, "Påskafton");
  addDynamicSpecialDay(dynamicSpecialDays, getDateString(easterDay), "Påskdagen");
  addDynamicSpecialDay(dynamicSpecialDays, easterMonday, "Annandag påsk");
  addDynamicSpecialDay(dynamicSpecialDays, fettisdagen, "Fettisdagen");
  addDynamicSpecialDay(dynamicSpecialDays, holyThursday, "Kristi himmelsfärdsdag");
  addDynamicSpecialDay(dynamicSpecialDays, pentecostEve, "Pingstafton");
  addDynamicSpecialDay(dynamicSpecialDays, getDateString(pentecost), "Pingstdagen");
  addDynamicSpecialDay(dynamicSpecialDays, pentecostPost, "Annandag pingst");
  addDynamicSpecialDay(dynamicSpecialDays, midsummerEvening, "Midsommarafton");
  addDynamicSpecialDay(dynamicSpecialDays, getDateString(midsummerDay), "Midsommardagen");
  addDynamicSpecialDay(dynamicSpecialDays, summerSolstice, "Sommarsolstånd");
  addDynamicSpecialDay(dynamicSpecialDays, winterSolstice, "Vintersolstånd");
  addDynamicSpecialDay(dynamicSpecialDays, marchEquinox, "Vårdagjämning");
  addDynamicSpecialDay(dynamicSpecialDays, septemberEquinox, "Höstdagjäming");
  addDynamicSpecialDay(dynamicSpecialDays, allSaintsEve, "Allhelgonaafton");   //"Alla helgons afton"
  addDynamicSpecialDay(dynamicSpecialDays, getDateString(allSaintsDay), "Alla helgons dag");

  if (electionDay !== "") {
    addDynamicSpecialDay(dynamicSpecialDays, electionDay, "Riksdagsval");
  }

  return dynamicSpecialDays
}

function addDynamicSpecialDay(dict, date, text) {
  let d = getDayAndMonthFromString(date);

  if (d in dict) {
    dict[d] = [...dict[d], text];
  } else {
    dict[d] = [text];
  }
}

function getNthAdventDate(year, n) {
  if (n > 4 || n <= 0) {
    return "";
  }
  var day = 24 * 60 * 60 * 1000
  var d = new Date(new Date(year, 11, 24, 12, 0, 0, 0).getTime() - (4-n) * 7 * day);
  while (d.getDay() !== 0) {
    d = new Date(d.getTime() - day);
  }
  return getDateString(d);
}

function nthDayInMonth(n, day, m, y) {
  // day is in range 0 Sunday to 6 Saturday
  var d = firstDayInMonth(day, m, y);
  return new Date(d.getFullYear(),
    d.getMonth(), d.getDate() + (n - 1) * 7, 12);
  }

function firstDayInMonth(day, m, y) {
  // day is in range 0 Sunday to 6 Saturday
    return new Date(y, m, 1 +
    (day - new Date(y, m, 1).getDay() + 7) % 7);
  }

function lastDayInMonth(day, m, y) {
    // day is in range 0 Sunday to 6 Saturday
    let nextMonth = new Date(y, m + 1, 1);
    let lastDay = new Date(nextMonth.getTime() - 24 * 60 * 60 * 1000).getDate();
    return new Date(y, m, lastDay +
    (day - new Date(y, m, lastDay).getDay() - 7) % 7, 12);
  }

function getEaster(year) {
  let a = year  % 19;
  let b = year % 4;
  let c = year % 7;
  const M = 24; // valid 1900-2199
  const N = 5; // valid 1900-2099
  let d = (19*a + M) % 30;
  let e = (2*b + 4*c + 6*d + N) % 7;
  let f = 22 + d + e;
  if (f <= 31) {
    return new Date(year, 2, f, 12)
  }
  else {
    f = f - 31;
    if (f === 26 || (f === 25 && d === 28 && e === 6 && a > 10)) {
      f = f-7;
    }
    return new Date(year, 3, f, 12)
  }
}
//Kristi himmelsfördsdagen
function getHolyThursday(easterDay) {
  return getDateString(new Date(easterDay.getTime() + 39 * 24 * 60 * 60 * 1000));
}

//Pingst
function getPentecost(easterDay) {
  return new Date(easterDay.getTime() + 49 * 24 * 60 * 60 * 1000);
}

function getFettisdagen(year) {
  const DAYS_BEFORE_EASTER = 47;
  let easter = getEaster(year);
  let fettisdagen = new Date(easter.getTime() - DAYS_BEFORE_EASTER * 24 * 60 * 60 * 1000);
  return fettisdagen;
}

function getMidsummerDay(year) {
  let possibleDates = [20, 21, 22, 23, 24, 25, 26]; 
  //20–26 June on a Saturday
  
  for (let date of possibleDates) {
    let possibleDate = new Date(year, 5, date);
    let day = possibleDate.getDay();
    if( day === 6) {
      return possibleDate;
    }
  }
  return undefined;
}

function getSummerSolstice(year) {
  // 20 June or 21 June
  let twentyDateYears = ["2016", "2020", "2024", "2028", "2032", "2036", "2040", "2044"]; //Update 2045 or 2049
  if (twentyDateYears.includes(year.toString())) {
    return year + "-06-20"
  } else {
    return year + "-06-21"
  }
}

function getWinterSolstice(year) {
  // 21 December or 22 December, 20 December gap years after 2084 or 2088
  let twentyTwoDateYears = ["2019", "2023", "2027", "2031", "2035", "2039", "2043", "2047"]; //Update 2047 or 2049
  if (twentyTwoDateYears.includes(year.toString())) {
    return year + "-12-22"
  } else {
    return year + "-12-21" //Update 2084 
  }
}
function getMarchEquinox(year) {
  return year + "-03-20" //2016-2025 until 2048, gap year 19
}

function getSeptemberEquinox(year) {
  let twentyTwoDateYears = ["2016", "2017", "2020", "2021", "2024", "2025", "2028", "2029", "2032", "2033", "2036", "2037", "2040", "2041"]; //Update 2042
  if (twentyTwoDateYears.includes(year.toString())) {
    return year + "-09-22"
  } else {
    return year + "-09-23" 
  }
}

function getElectionDay(year) {
  let start = 2018;
  if ((year - start) % 4 === 0) {
    return getDateString(nthDayInMonth(2, 0, 8, year));
  }
  return "";
}

function getAllSaintsDay(year) {
  //31 Oct - 06 Nov Saturday
  let possibleDate = new Date(year, 9, 31);

  for (let d = 0; d < 7; d++) {
    let day = possibleDate.getDay();
    if( day === 6) {
      return possibleDate;
    }
    possibleDate = addDays(possibleDate, 1)
  }
  
  return undefined;
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

