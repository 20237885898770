import React from 'react';
import CalendarDay from './CalendarDay';

const WEEK_FIELD = "week";

function CalendarMonth(props) {

  let weeks = {}

  for (const value of Object.values(props.monthInfo)) {
    weeks[value[WEEK_FIELD]] = value; 
  }
  
  let monthInfo = props.monthInfo; 
  let dates = Object.keys(monthInfo);
  let startDate = monthInfo[dates[0]];
  let endDate = monthInfo[dates[dates.length - 1]];
  let paddingBefore = monthPaddingBefore(startDate);
  let paddingAfter = monthPaddingAfter(endDate);
  let dayNames = ["Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag", "Söndag"];
  let weekNumbers = getWeeks(monthInfo);

  return (
    <div className="calendar-month-grid-container">
      
      <div className="calendar-header-container">
      <div className="calendar-month-grid-item"></div>
        {dayNames.map(dayName => <div key={dayName} className={`calendar-month-grid-item ${getDayStyling(dayName)}`}>{dayName}</div>)}
      </div>

      <div className="calendar-week-container">
        {weekNumbers.map(weekNr => <div key={weekNr} className="calendar-month-grid-item calendar-week-item"><p>{weekNr}</p></div>)}
      </div>
      <div className="calendar-dates calendar-date-grid-container">
        {[...Array(paddingBefore).keys()].map((padding, index) => <div key={index} className="calendar-month-grid-item"></div>)}

        {dates.map((date, index) => 
          <div key={index} className="calendar-month-grid-item">
            <CalendarDay date={date} dayInfo={monthInfo[date]} settings={props.settings} />
          </div>  
        )}

       {[...Array(paddingAfter).keys()].map((padding, index) => <div key={index} className="calendar-month-grid-item"></div>)}
      </div>

    </div>
  )
}

function getDayStyling(dayName) {
  if (dayName === "Söndag") {
    return "sunday";
  } else {
    return "";
  }
}

function monthPaddingBefore(date) {
  let day = date.day; //0 is Sunday
  return (day + 6) % 7;
}

function monthPaddingAfter(date) {
  let day = date.day; //0 is Sunday
  return (7 - day) % 7;
}

function getWeeks(monthInfo) { //TODO jump days
  const weeks = new Set();
  const dates = Object.keys(monthInfo)
  let i = 0
  for (; i < dates.length; i += 7) {
    weeks.add(monthInfo[dates[i]][WEEK_FIELD]);
  }
  weeks.add(monthInfo[dates[dates.length - 1]][WEEK_FIELD])
  
  return Array.from(weeks);
}

export default CalendarMonth;