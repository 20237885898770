import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import cuid from "cuid";
import PanZoomImage from './PanZoomImage'

const getClassName = (className, isActive, isEmpty) => {
  if(!isEmpty) {
    return `${className} ${className}-none`;
  }
  if (!isActive) {
    return className;
  }
  return `${className} ${className}-active`;
};

const DropZoneWithImage = ({accept}) => { 

  const [images, setImages] = useState([]);
  
    const removeImage = (imageId) => {

    setImages(images.filter((image) => image.id !== imageId ))
  }

  const onDrop = useCallback((acceptedFiles) => 
    {
      acceptedFiles.map(file => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = function(e) {
          setImages(prevState => [
            ...prevState,
            { id: cuid(), src: e.target.result }
          ]);
        };
        reader.readAsDataURL(file);
        return file;
      });


    }, [setImages])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  return (
    <div className="image-dropzone-container">
      <div className={getClassName("image-dropzone", isDragActive, images.length === 0)} {...getRootProps()}>
        
        {images.length === 0 && <div className="text-center">
          <input className="dropzone-input" {...getInputProps()} />
          {(isDragActive ? (
            <p className="dropzone-content hidden-print">Släpp bilden här</p>
          ) : (
            <p className="dropzone-content hidden-print">
              Dra och släpp ner bilden här eller klicka och välj en bild
            </p>
          ))}
        </div>}
      
      </div>
      {images.map(image => <Image key={image.id} image={image} removeImage={removeImage}/>)}  
    </div>
  );
};

const Image = ( { image, removeImage} ) => {
  return (
    <div className="drop-image">
      <PanZoomImage imageSrc={image.src} imageId={image.id} removeImage={removeImage}/>
    </div>
  );
};

export default DropZoneWithImage;