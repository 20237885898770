import React, { useState } from "react";
import DropZoneWithImage from './DropZoneWithImage'
import CalendarMonth from './CalendarMonth'

function CalendarMonthBuilder(props) {
  const [rows, setRows] = useState(1);
  const [columns, setColumns] = useState(1);
  const [custom, setCustom] = useState(false);
  const [customText, setCustomText] = useState("Egen");
  const [numberOfCustomRows, setNumberOfCustomRows] = useState(0);

  const setGrid = (rows, columns) => {
    setRows(rows);
    setColumns(columns);
    setCustom(false);
    setCustomText("Egen");
  }

  const setCustomSetting = () => {
    if (customText === "Reset") {
      setNumberOfCustomRows(0);
    }
    let text = "Reset"
    setCustomText(text);
    setCustom(true);
  }

  let header = 
  <header className="calendar-month-header">
    <h1>{props.month} {props.settings.showCalendarYear? props.year: null} </h1>
  </header>
  let calendarImage = 
  <div className="calendar-images-wrapper">
    {custom ? <ImageGridRows numberOfCustomRows={numberOfCustomRows} setNumberOfCustomRows={setNumberOfCustomRows}></ImageGridRows>
    : <ImageGrid rows={rows} columns={columns}/>}
  </div>
  let calendarMonth = <CalendarMonth monthInfo={props.monthInfo} settings={props.settings} />

  return (
    <React.Fragment>
      <div className="grid-image-options outer-image-options hidden-print">
        <button onClick={() => setGrid(1, 1)}>
          1 x 1
        </button>
        <button onClick={() => setGrid(2, 1)}>
          2 x 1
        </button>
        <button onClick={() => setGrid(1, 2)}>
          1 x 2
        </button>
        <button onClick={() => setGrid(2, 2)}>
          2 x 2
        </button >
        <button onClick={() => setGrid(3, 1)}>
          3 x 1
        </button>
        <button onClick={() => setGrid(1, 3)}>
          1 x 3
        </button>
        <button onClick={() => setGrid(3, 2)}>
          3 x 2
        </button >
        <button onClick={() => setGrid(2, 3)}>
          2 x 3
        </button >
        <button onClick={() => setGrid(3, 3)}>
          3 x 3
        </button>
        <button onClick={() => setCustomSetting()}>
          {customText}
        </button>
      </div>
      <div className="calendar-month-wrapper">
        <CalenderMonthComponents props={props} header={header} calendarImage={calendarImage} calendarMonth={calendarMonth}/>
      </div>
  </React.Fragment>
  )
}

function CalenderMonthComponents({props, header, calendarImage, calendarMonth}) {
  let headerPlacement = "top";
  let imagePlacement = "middle";
  let calendarPlacement = "bottom";

  if (props.settings.headerPlacement === "Top") {
    headerPlacement = "top";
    imagePlacement = "middle";
    calendarPlacement = "bottom";
  } else if (props.settings.headerPlacement === "Middle") {
    headerPlacement = "middle";
    imagePlacement = "top";
    calendarPlacement = "bottom";
  }  else {
    headerPlacement = "bottom";
    imagePlacement = "top";
    calendarPlacement = "middle";
  }


  return (
    <div className="box-column">
      <div className={`header-height box-column-${headerPlacement}`}>{header}</div>
      <div className={`image-height  box-column-${imagePlacement}`}>{calendarImage}</div>
      <div className={`calendar-height box-column-${calendarPlacement}`}>{calendarMonth}</div>
    </div>
  )
}

const ImageGrid = ( {columns, rows}) => {
  let col_percent = 100 / columns;
  let row_percent = 100 / rows;
  let style = {
      display: "grid",
      gridTemplateColumns: `repeat( auto-fit, ${col_percent}%)`,
      gridTemplateRows: `repeat( auto-fit, ${row_percent}%)`
  }

  let images = [];
  for (let i = 0; i < rows*columns; i++) {
    images.push(<div key={i} className="grid-image-item"><DropZoneWithImage accept={"image/*"}/></div>)
  }

  return (
    <div style={style} className="calendar-images"> 
      {images}
    </div>
  )
}

const ImageGridRows = ({numberOfCustomRows, setNumberOfCustomRows}) => { 
  const selectRows = <div className="hidden-print "> 
    <p className="option-text">Välj antal rader med bilder</p>
    <div className="grid-image-options">
    <button onClick={() => setNumberOfCustomRows(1)}>1</button>
    <button onClick={() => setNumberOfCustomRows(2)}>2</button>
    <button onClick={() => setNumberOfCustomRows(3)}>3</button>
    <button onClick={() => setNumberOfCustomRows(4)}>4</button>
    </div>
  </div>
  const display = (numberOfCustomRows === 0) ? selectRows: <ImageGridColumns rows={numberOfCustomRows} />
  return (
    display
  )
}

const ImageGridColumns = ( {rows}) => {
  const [imagesPerRow, setImagesPerRow] = useState([0, 0, 0, 0, 0]);
  const setRowImages = (index, images) => {
    let update = imagesPerRow.slice();
    update[index] = images;
    setImagesPerRow(update);
  }

  let row_percent = 100 / rows;
  let style = {
      display: "grid",
      gridTemplateRows: `repeat( auto-fit, ${row_percent}%)`,
      height: "100%"
  }

  let rowDivs = []
  for (let i = 0; i < rows; i++) {
    rowDivs.push(    
    <div key={i}>
      <Images i={i} imagesPerRow={imagesPerRow} setRowImages={setRowImages}></Images>
    </div>)
  }

  return (
    <div style={style}> 
      {rowDivs}
    </div>
  )

}

function Images( {i, imagesPerRow, setRowImages} ) {
  if (imagesPerRow[i] === 0) {
    return (
      <div className="hidden-print"> 
        <p className="option-text">Välj antal bilder</p>
        <div className="grid-image-options">
          <button onClick={() => setRowImages(i, 1)}>1</button>
          <button onClick={() => setRowImages(i, 2)}>2</button>
          <button onClick={() => setRowImages(i, 3)}>3</button>
          <button onClick={() => setRowImages(i, 4)}>4</button>
        </div>
      </div>
    )
  } else {
      return (
        <ImageGrid rows={1} columns={imagesPerRow[i]} />
      )
  } 
}

export default CalendarMonthBuilder;